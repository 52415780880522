import axios from 'axios';
import { useEffect, useState } from 'react';
import { CAMPAIGNS_NAMES } from '../constants/endpoints';

export function useGetCampaigns(props) {
    const [campaignList, setCampaignList] = useState([]);
    const [campaignMapper, setCampaignMapper] = useState({});
    
    useEffect(() => {
        getCampaigns();
    },[]);

    const getCampaigns = () => {
        axios.get(CAMPAIGNS_NAMES + 'campaign')
          .then((res) => {
            if (res?.data?.status) {
                setCampaignList(res?.data?.response);
                const mapper = res?.data?.response?.reduce((previous, current) => {
                    previous[current?.id] = current?.campaign_title;
                    return previous;
                }, {});

                setCampaignMapper(mapper);
            }
          }).catch((error) => {
    
          });
    };

    return {campaignList, getCampaigns, campaignMapper};
};